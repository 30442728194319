<template>
  <div class="div-form-main"   v-loading="formLoading">
    <div class="div-form-main-btn" >
      <el-button round type="primary" style="min-width: 100px" size="small" @click="onCreateOrUpdate" v-if="view === TERM_VIEW.CREATE_TERM">Submit</el-button>
      <el-button round type="primary" style="min-width: 100px" size="small" @click="onCreateOrUpdate" v-if="view === TERM_VIEW.EDIT_TERM && !isAllFieldsDisabled">Save</el-button>
      <el-button round type="primary" style="min-width: 100px" size="small"
        v-if="(view === TERM_VIEW.EDIT_TERM && isAllFieldsDisabled && hasPermission($permissionFunctionCode.EDIT))" @click="isAllFieldsDisabled = false"
        :disabled="!hasEditPermission && termContractType === TERM_CONTRACT_TYPE.TC"
      >
        Edit
      </el-button>
      <el-button round type="primary" style="min-width: 100px" size="small"  @click="handleCloseForm('CLOSE')">Close</el-button>
    </div>
    <div class="div-form-main-container-second term-contract"  v-if="view === TERM_VIEW.CREATE_TERM||(view === TERM_VIEW.EDIT_TERM && termContractFormData)">
        <!-- <div class="" > -->
          <contract-infomation
            class="basic-card-block"
            ref="contractInfoForm"
            :title="'Contract Infomation'"
            :formData.sync="termContractFormData"
            :rules="rules"
            :isEdit="view === TERM_VIEW.EDIT_TERM"
            :hasEditPermission="hasEditPermission"
            :termContractType="termContractType"
            :currentCompany="currentCompany"
            :isAllFieldsDisabled="isAllFieldsDisabled"
            :companyOptionList="creditAssociationsCompanyOptionList"
          />
          <company-infomation
            class="basic-card-block"
            v-if="creditAssociationsCompanyOptionList.length > 0"
            ref="sellerInfoForm"
            isSellerForm
            :rules="rules"
            :title="[TERM_CONTRACT_TYPE.EX_WHARF, TERM_CONTRACT_TYPE.DELIVERED].includes(termContractType) ? 'Seller Infomation' : 'Owner Infomation'"
            :formData.sync="termContractFormData"
            :isEdit="view === TERM_VIEW.EDIT_TERM"
            :hasEditPermission="hasEditPermission"
            :termContractType="termContractType"
            :isAllFieldsDisabled="isAllFieldsDisabled"
            :currentCompany="currentCompany"
            :companyOptionList="creditAssociationsCompanyOptionList"
            @getBargesListBySeller="getBargesListBySeller"
          />
          <company-infomation
            class="basic-card-block"
            v-if="creditAssociationsCompanyOptionList.length > 0"
            ref="buyerInfoForm"
            :rules="rules"
            :title="[TERM_CONTRACT_TYPE.EX_WHARF, TERM_CONTRACT_TYPE.DELIVERED].includes(termContractType) ? 'Buyer Infomation' : ' Charterer Infomation'"
            :formData.sync="termContractFormData"
            :isEdit="view === TERM_VIEW.EDIT_TERM"
            :hasEditPermission="hasEditPermission"
            :termContractType="termContractType"
            :isAllFieldsDisabled="isAllFieldsDisabled"
            :currentCompany="currentCompany"
            :companyOptionList="creditAssociationsCompanyOptionList"
            @getBargesListBySeller="getBargesListBySeller"
          />

          <barge-information
            class="basic-card-block"
            v-if="[TERM_CONTRACT_TYPE.TC,TERM_CONTRACT_TYPE.COA].includes(termContractType)"
            ref="bargeInfoForm"
            :rules="rules"
            :title="'Barge Information'"
            :formData="bargeFormData"
            :isEdit="view === TERM_VIEW.EDIT_TERM"
            :termContractType="termContractType"
            :isAllFieldsDisabled="isAllFieldsDisabled"
            :bargeOptionList="bargeOptionList"
            :entityData="termContractFormData"
          />
          <!-- view is not currently useful-->
          <product-information
            class="basic-card-block"
            ref="productInfoForm"
            :rules="rules"
            :view="view"
            :title="'Product Information'"
            :formData="productFormData"
            :isEdit="view === TERM_VIEW.EDIT_TERM"
            :hasEditPermission="hasEditPermission"
            :termContractType="termContractType"
            :isAllFieldsDisabled="isAllFieldsDisabled"
          />
        <!-- </div> -->
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { TERM_CONTRACT_TAB_LIST, TERM_CONTRACT_TYPE, TERM_VIEW } from '@/constants.js'
import ContractInfomation from './ContractInfomation.vue'
import CompanyInfomation from './CompanyInfomation.vue'
import BargeInformation from './BargeInformation.vue'
import ProductInformation from './ProductInformation.vue'
import { modifyTermContractBarges } from '@/services/modules/term.js'
export default {
  name: 'TermContractForm',
  components: { ContractInfomation, CompanyInfomation, BargeInformation, ProductInformation },
  props: {
    view: String,
    termContractType: String,
    formId: String
  },
  data () {
    const rules = {
      reqRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }],
      notReqRule: [{ required: false }]
    }
    return {
      // currentCompany: this.$store.state.currentCompany,
      isOnline: Object.keys(this.$store.state.currentCompany).length > 0,
      rules,
      TERM_VIEW,
      TERM_CONTRACT_TYPE,
      TERM_CONTRACT_TAB_LIST,
      sellerId: null,
      buyerId: null,
      currencyOptionList: [],
      creditAssociationsCompanyOptionList: [],
      counterpartyUserList: [],
      vesselOptionList: [],
      bargeOptionList: [],
      sysProductOptionList: [],
      sysPproductSpecsOptionList: [],
      termContractFormData: null,
      contractFormData: null,
      buyerFormData: null,
      sellerFormData: null,
      bargeFormData: null,
      productFormData: null,
      formLoading: false,
      isAllFieldsDisabled: true,
      hasEditPermission: true
    }
  },
  computed: {
    ...mapState(['productSpecificationList','currentCompany'])
  },
  created () {
    if (this.view === TERM_VIEW.EDIT_TERM && this.formId) {
      this.getSingeTermContract(this.formId).then(res => {
        this.bargeFormData = res.barges
        this.productFormData = res.products
        this.termContractFormData = res
        this.sellerId = res.sellerSysOrganizationId
        this.buyerId = res.buyerSysOrganizationId
        this.getInitFormData()
      })
    } else if (this.view === TERM_VIEW.CREATE_TERM) {
      this.isAllFieldsDisabled = false
      this.getInitFormData()
    } else {
      this.getInitFormData()
    }
  },
  mounted () {
  },
  methods: {
    convertToNewObj (obj, txt) {
      if (!!obj && Object.keys(obj).length > 0) {
        return Object.keys(obj).reduce((newObj, key) => {
          newObj[txt + key.replace(/^\S/, s => s.toUpperCase())] = obj[key]
          return newObj
        }, {})
      }
    },
    getVesselList () {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.shipBaseUrl}?pageNumber=1&pageSize=999999`
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data.records)
          }
        })
      })
    },
    getCreditAssociations () {
      return new Promise(resolve => {
        if (this.isOnline) {
          this.$request.get({
            url: `${this.$apis.creditAssociations}?pageNumber=0&pageSize=9999`
          }).then(res => {
            if (res?.code === 1000) {
              resolve(res.data.content)
            }
          })
        } else {
          resolve([])
        }
      })
    },
    getSingeTermContract (id) {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.termContract}/${id}`
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },
    postTermContract (data) {
      return new Promise(resolve => {
        this.$request.post({
          url: this.$apis.termContract,
          data
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          } else {
            const title = 'Submit Failed'
            this.openErrMsg(res.message, title)
          }
        })
      })
    },
    putTermContract (data) {
      return new Promise(resolve => {
        this.$request.put({
          url: this.$apis.termContract,
          data
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },
    postLinkAttachments (id, data) {
      const url = `${this.$apis.termContract}/${id}/link_attachments`
      return new Promise(resolve => {
        this.$request.post({
          url,
          data
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          } else {
            const title = 'Upload Attachments Failed'
            this.openErrMsg(res.message, title)
          }
        })
      })
    },
    getUserListByCompany (companyId, isOnline) {
      if (isOnline) {
        return new Promise((resolve, reject) => {
          this.$request.get({
            url: `${this.$apis.sharedUserBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${companyId}`,
          }).then(res => {
            if (res?.code === 1000) {
              const userList = res.data.records || []
              resolve(userList)
            }
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          if (isOnline) {
            this.$request.get({
              url: `${this.$apis.offlineOrganizationContact}?filter=sysOfflineOrganizationId : '${companyId}'&pageNumber=0&pageSize=9999`
            }).then(res => {
              if (res?.code === 1000) {
                const contactList = res.data.content
                resolve(contactList)
              }
            })
          } else {
            resolve([])
          }
        })
      }
    },
    reqCreateOrUpdateContract (payLoadData) {
      const { attachments } = payLoadData
      payLoadData.termContractType = this.termContractType
      delete payLoadData.attachments
      if (this.view === this.TERM_VIEW.CREATE_TERM) {
        this.postTermContract(payLoadData).then(res => {
          if (attachments && attachments.length > 0) {
            this.postLinkAttachments(res.id, attachments).then(resp => {
              this.handleCloseForm('Create')
            })
          } else {
            this.handleCloseForm('Create')
          }
        })
      } else if (this.view === this.TERM_VIEW.EDIT_TERM) {
        payLoadData.id = this.termContractFormData.id
        if (this.sellerId !== this.currentCompany.id && this.termContractType === TERM_CONTRACT_TYPE.COA) {
          modifyTermContractBarges({ id: this.termContractFormData.id, barges: payLoadData.barges }).then(res => {
            if (res?.code === 1000) {
              this.handleCloseForm('Edit')
            }
          })
        } else {
          this.putTermContract(payLoadData).then(res => {
            if (attachments && attachments.length > 0) {
              this.postLinkAttachments(res.id, attachments).then(resp => {
                this.handleCloseForm('Edit')
              })
            } else {
              this.handleCloseForm('Edit')
            }
          })
        }
      }
    },
    getInitFormData () {
      this.formLoading = true
      Promise.all([this.getCreditAssociations(), this.getVesselList()]).then(val => {
        this.creditAssociationsCompanyOptionList = val[0]
        const myCompany = {
          counterpartySysOrganizationId: this.currentCompany.id,
          counterpartyCompanyName: this.currentCompany.companyName,
          offline: this.currentCompany.offline,
          status: 'ACTIVE',
          isOnlineCounterpartys: this.currentCompany.offline
        }
        this.creditAssociationsCompanyOptionList.unshift(myCompany)
        this.vesselOptionList = val[1]
        this.formLoading = false
        if (this.view === TERM_VIEW.EDIT_TERM && this.formId && this.termContractFormData) {
          this.bargeOptionList = val[1].filter(vessel => vessel.tenantId === this.termContractFormData.sellerSysOrganizationId)
          if ((this.termContractType === this.TERM_CONTRACT_TYPE.COA || this.termContractType === this.TERM_CONTRACT_TYPE.TC) && this.termContractFormData.sellerSysOrganizationId !== this.currentCompany.id) {
            this.hasEditPermission = false
          }
        }
      })
    },
    onCreateOrUpdate () {
      this.contractFormData = this.$refs.contractInfoForm.getContractFormData()
      const buyerData = this.$refs.buyerInfoForm.getCompanyFormData()
      this.buyerFormData = buyerData && this.convertToNewObj(buyerData, 'buyer')
      const sellerData = this.$refs.sellerInfoForm.getCompanyFormData()
      this.sellerFormData = buyerData && this.convertToNewObj(sellerData, 'seller')
      this.productFormData = this.$refs.productInfoForm.getProductFormData()
      if ([this.TERM_CONTRACT_TYPE.COA, this.TERM_CONTRACT_TYPE.TC].includes(this.termContractType)) {
        this.bargeFormData = this.$refs.bargeInfoForm.getBargeFormData()
        if (this.termContractType === this.TERM_CONTRACT_TYPE.TC) {
          this.bargeFormData.map(b => {
            b.startDate = this.contractFormData.dateRange[0]
            b.endDate = this.contractFormData.dateRange[1]
          })
        } else {
          this.bargeFormData.map(b => {
            b.startDate = b.dateRange[0]
            b.endDate = b.dateRange[1]
          })
        }
      }
      if (this.contractFormData && this.buyerFormData && this.sellerFormData && this.productFormData) {
        if ([this.sellerFormData.sellerSysOrganizationId, this.buyerFormData.buyerSysOrganizationId].includes(this.currentCompany.id) && (this.sellerFormData.sellerSysOrganizationId !== this.buyerFormData.buyerSysOrganizationId)) {
          const payLoadData = { ...this.contractFormData, ...this.buyerFormData, ...this.sellerFormData, products: this.productFormData }


          switch (this.termContractType) {
            case this.TERM_CONTRACT_TYPE.COA:
            case this.TERM_CONTRACT_TYPE.TC:
              if (this.productFormData && this.bargeFormData) {
                payLoadData.barges = this.bargeFormData
                this.reqCreateOrUpdateContract(payLoadData)
              }
              break
            case this.TERM_CONTRACT_TYPE.DELIVERED:
            case this.TERM_CONTRACT_TYPE.EX_WHARF:
              this.reqCreateOrUpdateContract(payLoadData)
              break
            default:
              break
          }
        } else {
          const title = 'Error Message'
          const errMsg = 'Your company must be either a buyer or seller in the contract'
          this.openErrMsg(errMsg, title)
        }
      }
    },
    handleCloseForm (action) {
      this.$emit('handleCloseForm', action)
    },
    getBargesListBySeller (id, isSellerForm) {
      if (isSellerForm) {
        this.sellerId = id
        this.bargeOptionList = this.vesselOptionList.filter(vessel => vessel.tenantId === id)
      } else {
        this.buyerId = id
      }
    },
    openErrMsg (errMsg, title) {
      this.$alert(`${errMsg}, please check and try again.`, `${title}`, {
        confirmButtonText: 'Close'
      })
    }

  }
}
</script>
<style lang="scss" scoped>
  @import "../index";
</style>
